import type { ObjectDirective } from 'vue'

// v-autofocus: Focuses an element when it's inserted in the DOM.
//
// Can also receive a boolean expression to determine whether to focus.
const vAutofocus: ObjectDirective<any, boolean> = {
  mounted (el, binding) {
    if (binding.value ?? true) {
      nextTick(() => {
        const inputOrElement = el.querySelector('input, textarea') || el
        inputOrElement?.focus()
      })
    }
  },
}

export default vAutofocus
